import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AgentMessagingSettingsService } from '../agent-messaging-settings.service';
import { ToasterService } from '@abp/ng.theme.shared';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-agent-messaging-settings',
  templateUrl: './agent-messaging-settings.component.html',
})
export class AgentMessagingSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  availableMethods: string[] = [];
  isInitialized: boolean;
  loading: boolean;
  selected: any = {};
  showCredentialFields = false;
  ndaClient = 'Nda Client';

  constructor(
    private agentMessagingSettingsService: AgentMessagingSettingsService,
    private toaster: ToasterService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.agentMessagingSettingsService.getSettings().subscribe((settings: any) => {
      this.availableMethods = settings.availableMethods;
      this.selected = settings;
      this.isInitialized = true;
      this.buildForm();
    });
  }

  buildForm() {
    const { method, callbackUrl, identityUrl, clientId, clientSecret, scope } = this.selected;
    this.form = this.fb.group({
      method: [method ?? null, [Validators.required]],
      callbackUrl: [callbackUrl ?? null, []],
      identityUrl: [identityUrl ?? null, []],
      clientId: [clientId ?? null, []],
      clientSecret: [clientSecret ?? null, []],
      scope: [scope ?? null, []],
    });

    this.showCredentialFields = method !== this.ndaClient;

    this.form.get('method').valueChanges.subscribe(value => {
      this.showCredentialFields = value !== this.ndaClient;
    });
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    const settings: any = {
      ...this.form.value,
    };

    this.loading = true;
    this.agentMessagingSettingsService
      .updateSettings(settings)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => this.toaster.success('AbpSettingManagement::SuccessfullySaved', null));
  }
}
