import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { tenantEntityPropContributors } from './shared/entity-contributors/tenant-entity-prop-contributor';
import { tenantEditFormPropContributors } from './shared/form-contibutors/tenant-form-prop-contributor';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'premium-reporting',
    loadChildren: () => import('@sestek/premium-reporting').then(m => m.PremiumReportingModule.forLazy()),
  },
  {
    path: 'chat-dashboard',
    loadChildren: () => import('@app/chat-dashboard/chat-dashboard.module').then(m => m.ChatDashboardModule),
  },
  {
    path: 'monitoring',
    loadChildren: () => import('@app/monitoring/monitoring.module').then(m => m.MonitoringModule),
  },
  {
    path: 'agent-reporting',
    loadChildren: () => import('@app/agent-reporting/agent-reporting.module').then(m => m.AgentReportingModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'agent-chat',
    loadChildren: () => import('@app/chat-application/chat.module').then(m => m.ChatModule),
  },
  {
    path: 'test-chat',
    loadChildren: () => import('@app/test-chat/test-chat.module').then(m => m.TestChatModule),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () => import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () =>
      import('@volo/abp.ng.saas').then(m =>
        m.SaasModule.forLazy({
          editFormPropContributors: tenantEditFormPropContributors,
          entityPropContributors: tenantEntityPropContributors,
        })
      ),
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () => import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m => m.TextTemplateManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () => import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'agents',
    loadChildren: () => import('@app/agents/agent.module').then(m => m.AgentModule),
  },
  {
    path: 'queue',
    loadChildren: () => import('@app/queues/queue.module').then(m => m.QueueModule),
  },
  {
    path: 'skills',
    loadChildren: () => import('@app/skills/skills.module').then(m => m.SkillsModule),
  },
  {
    path: 'states-management',
    loadChildren: () => import('@app/states-managements/states-management.module').then(m => m.StatesManagementModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
