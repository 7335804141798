<ul class="navbar-nav toolbar-nav">
  <li class="nav-item d-flex align-items-center">
    <abp-back-to-impersonator-nav-item></abp-back-to-impersonator-nav-item>
  </li>
  <li class="nav-item d-flex align-items-center">
    <abp-languages></abp-languages>
  </li>

  <li class="nav-item" *ngIf="currentUser$ | async as user">
    <ng-template #loginBtn>
      <a role="button" class="nav-link pointer" (click)="navigateToLogin()">
        {{ 'AbpAccount::Login' | abpLocalization }}
      </a>
    </ng-template>

    <div *ngIf="user.isAuthenticated; else loginBtn" class="dropdown btn-group" ngbDropdown>
      <a ngbDropdownToggle class="btn pointer">
        <abp-current-user-image classes="user-avatar"></abp-current-user-image>
        <span class="ms-1">
          <small *ngIf="(selectedTenant$ | async)?.name as tenantName">
            <i>{{ tenantName }}</i>
            <span>\</span>
          </small>
          <span>{{ user.userName }}</span>
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <div class="p-2 row">
          <div class="pe-0 col col-auto">
            <abp-current-user-image classes="user-avatar-big"></abp-current-user-image>
          </div>
          <div class="ps-2 col">
            <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
            ><br />
            <small *ngIf="(selectedTenant$ | async)?.name as tenantName">
              <i>{{ tenantName }}</i>
              <span>\</span>
            </small>
            <strong>{{ user.userName }}</strong>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <a class="dropdown-item pointer" (click)="onClickMyAccount()">
          {{ 'AbpAccount::MyAccount' | abpLocalization }}
        </a>

        <a class="dropdown-item pointer" (click)="onClickSecurityLogs()">
          {{ 'AbpAccount::MySecurityLogs' | abpLocalization }}
        </a>

        <a class="dropdown-item pointer" (click)="openVersionModal(versionInfoModal)">
          {{ '::About' | abpLocalization }}
        </a>

        <a class="dropdown-item pointer" id="logout" (click)="logout()">
          {{ 'AbpUi::Logout' | abpLocalization }}
        </a>
      </div>
    </div>
  </li>

  <li class="nav-item d-flex align-items-center">
    <abp-full-screen></abp-full-screen>
  </li>
</ul>

<ng-template #versionInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center">
      <abp-logo></abp-logo>
    </h4>
  </div>
  <div class="modal-body">
    <app-version-info></app-version-info>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>
