import { ConfigStateService, PermissionService, ReplaceableComponentsService } from '@abp/ng.core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { Component, OnInit } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  PremiumReportingAddMenuService,
  PremiumReportingConfigService,
  PremiumReportingMenuService,
} from '@sestek/premium-reporting/config';
import { AgentMessagingSettingsComponent } from './agent-messaging-settings/components/agent-messaging-settings.component';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { FooterComponent } from './shared/footer/footer.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private settingTabs: SettingTabsService,
    private premiumReportingConfigService: PremiumReportingConfigService,
    private premiumReportingMenuService: PremiumReportingMenuService,
    private premiumReportingAddMenuService: PremiumReportingAddMenuService,
    private permissionService: PermissionService,
    private config: ConfigStateService,
    private ngbTooltipConfig: NgbTooltipConfig
  ) {
    ngbTooltipConfig.openDelay = 400;

    settingTabs.add([
      {
        component: AgentMessagingSettingsComponent,
        name: 'LiveChat::AgentMessagingSettings',
        order: 4,
        requiredPolicy: 'LiveChat.AgentMessaging.SettingsManagement',
      },
    ]);
  }

  ngOnInit() {
    const isPremiumReportingFeatureEnabled = this.config.getFeature('PremiumReportingGroup.PremiumReporting');

    if (
      this.permissionService.getGrantedPolicy('PremiumReporting.PremiumReports') &&
      isPremiumReportingFeatureEnabled === 'true'
    ) {
      this.getPremiumReportingMenuList();
      this.premiumReportingMenuService.premiumReportingMenuChange.subscribe(() => this.getPremiumReportingMenuList());
    }

    this.replaceableComponents.add({
      component: NavItemsComponent,
      key: eThemeBasicComponents.NavItems,
    });

    this.replaceableComponents.add({
      component: FooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });
  }

  getPremiumReportingMenuList() {
    this.premiumReportingConfigService
      .get({ maxResultCount: 50 })
      .subscribe(res => this.premiumReportingAddMenuService.addMenu(res.items));
  }
}
